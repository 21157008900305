import { Component, Vue } from "vue-property-decorator";

export interface SnackbarProps {
  text: string;
  timeout?: number;
  color?: string | undefined;
  outlined?: boolean;
  position: "top" | "bottom" | "left" | "right" | "top-right";
  show?: boolean;
}

@Component({ name: "Snackbar" })
export default class Snackbar extends Vue {
  private options = {
    show: false,
    text: "",
    timeout: -1,
    color: undefined,
    position: "right",
    outlined: false,
  } as SnackbarProps;

  created() {
    this.$bus.$on("showSnackbar", this.showSnackbar);
  }

  private showSnackbar({ text, timeout, color, position, outlined }: SnackbarProps) {
    this.options = {
      text,
      color,
      timeout: timeout || -1,
      position: position || "right",
      outlined: outlined || false,
      show: true,
    };
  }

  get position() {
    return { [this.options.position]: true, top: true };
  }
}
