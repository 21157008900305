<template>
  <v-snackbar
    v-bind="position"
    multi-line
    v-model="options.show"
    :timeout="options.timeout"
    class="text-center"
    :color="options.color"
    :outlined="options.outlined"
  >
    <h3>{{ options.text }}</h3>
    <template v-slot:action="{ attrs }">
      <v-btn
        :color="options.outlined ? options.color : undefined"
        text
        v-bind="attrs"
        @click="options.show = false"
      >
        Fechar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import Snackbar from "./Snackbar";

export default Snackbar;
</script>
